<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <div class="container-fluid mt-4">  
        <b-alert :show="loading" variant="info">Loading...</b-alert>
        <b-row>
            <b-col>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>License Id</th>
                            <th>Serial Number</th>
                            <th>App Version</th>
                            <th>Last Seen</th>  
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="tablet in tablets" :key="tablet.id">
                            <td>{{ tablet.licenseId }}</td>
                            <td>{{ tablet.serialNumber }}</td>
                            <td>{{ tablet.appVersion }}</td>
                            <td>{{ toLocaleDateTime(tablet.lastSeen) }}</td>
                        </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { formatDateTimeToLocaleString } from '@/components/utils.js'
    import ApiService from '@/services/api.service';

    export default {
        name: "SiteTablets",
        data() {
            return {
                loading: false,
                tablets: []
            };
        },
        async created() {
            this.refreshTablets();
        },
        computed: {
            ...mapGetters('auth', ['isIntentoSales'])
        },
        methods: {
            async refreshTablets() {
                this.loading = true;
                this.tablets = await ApiService.getSiteTablets(this.$parent.siteId);
                this.loading = false;
            },
            toLocaleDateTime(d) {
                return formatDateTimeToLocaleString(d)
            }
        }
    };
</script>